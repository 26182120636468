
import { defineComponent } from 'vue'
import platformClient from 'purecloud-platform-client-v2'
import genesysCloudService from './../services/genesyscloud-service'

export default defineComponent({
  name: 'ModalSMS',
  props: {
    show: {
      type: Boolean
    },
    cancel: {
      type: Function
    },
    firstname: {
      type: String
    },
    lastname: {
      type: String
    },
    phoneNumber: {
      type: String
    },
    from: {
      type: String
    },
    externalContactId: {
      type: String
    },
    userId: {
      type: String
    }
  },
  data () {
    return {
      responses: [] as platformClient.Models.Response[],
      template: '',
      selected: 'Sélectionner ....',
      defaultChoice: 'Sélectionner ....',
      message: '',
      messageSent: 'false',
      maxCharacter: 160,
      remainingCharacters: 160,
      conversationId: '',
      libraryId: '5762b8a5-c89e-43b1-8906-fbc5fe5fdb44'
    }
  },
  methods: {
    onChange (event: Event) {
      if (event.target) {
        this.template = (event.target as HTMLInputElement).value
        if (this.firstname) {
          this.message = this.template.replace('{{CUSTOMER_NAME}}', this.firstname)
        } else {
          this.message = this.template
        }
      }
    },
    async runAction () {
      const body = { flowId: 'fb5e7dfd-613d-4c9b-b4ad-4d4555b96db6', to: this.phoneNumber, from: this.from, message: this.message, agentId: this.userId, externalContactId: this.externalContactId }
      await genesysCloudService.executeAction('custom_-_924185db-d0a4-4792-bf5f-58aa68ac1e96', body)
        .then(() => {
          this.messageSent = 'true'
          this.close()
        })
        .catch(err => {
          console.log(err)
          this.messageSent = 'error'
          this.close()
        })
    },
    calcRemainChar () {
      this.remainingCharacters = this.maxCharacter - this.message.length
    },
    close () {
      this.message = ''
      this.selected = this.defaultChoice
      this.remainingCharacters = this.maxCharacter
      this.$emit('closeModalSMS', this.messageSent)
      this.messageSent = 'false'
    },
    convertToPlain (html: string): string {
      const tempDivElement = document.createElement('div')
      tempDivElement.innerHTML = html
      return tempDivElement.textContent || tempDivElement.innerText || ''
    }
  },
  async created (): Promise<void> {
    await genesysCloudService.getResponseLibrary(this.libraryId)
      .then((responses) => {
        if (responses) {
          this.responses = responses
        }
      })
  }
})


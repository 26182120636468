import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a4fcfa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overlay" }
const _hoisted_2 = { class: "sms-container" }
const _hoisted_3 = { class: "sms-dialog" }
const _hoisted_4 = { class: "sms-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isSent)
            ? (_openBlock(), _createBlock(_component_fa, {
                key: 0,
                icon: "fa-solid fa-circle-check",
                class: "icon-sent"
              }))
            : (_openBlock(), _createBlock(_component_fa, {
                key: 1,
                icon: "fa-solid fa-circle-xmark",
                class: "icon-error"
              }))
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.description), 1),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "button"
        }, "OK")
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.show]
  ])
}

import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    show: {
      type: Boolean
    },
    description: {
      type: String
    },
    cancel: {
      type: Function
    }
  }
})


import platformClient from 'purecloud-platform-client-v2'
import genesysCloudService from './../services/genesyscloud-service'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'QueueList',
  data () {
    return {
      queues: [] as platformClient.Models.Queue[],
      selectedOption: false,
      queueAddress: ''
    }
  },
  methods: {
    onChange (event: Event) {
      if (event.target) {
        this.queueAddress = (event.target as HTMLInputElement).value
        this.selectedOption = true
        this.$emit('queueSelected', [this.queueAddress, this.selectedOption])
      }
    },
    checkQueueAddress () {
      console.log('Function invoked')
    }
  },
  emits: ['queueSelected'],
  created () {
    genesysCloudService.getQueues()
      .then(queues => {
        if (queues) {
          const filteredQueues = queues.filter(queue => {
            return queue.callingPartyNumber !== undefined
          })
          this.queues = filteredQueues
        }
      })
      .catch(err => console.error(err))
  }
})

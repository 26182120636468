
import platformClient from 'purecloud-platform-client-v2'
import genesysCloudService from './../services/genesyscloud-service'
import Spinner from './Spinner.vue'
import NotFound from './NotFound.vue'
import QueueList from './QueueList.vue'
import QueueNotSelectedVue from './QueueNotSelected.vue'
import ModalSMS from './ModalSMS.vue'
import ConfirmationSMS from './ConfirmationSMS.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomePage',
  components: { Spinner, NotFound, QueueList, QueueNotSelectedVue, ModalSMS, ConfirmationSMS },
  props: {
    userId: {
      type: String
    }
  },
  data () {
    return {
      contactsFound: false,
      isLoading: false,
      isDataLoaded: false,
      selectedOption: false,
      showAlert: false,
      showSMS: false,
      showConfSMS: false,
      isMobileValid: false,
      isWorkValid: false,
      isSent: false,
      searchStr: '',
      queueAddress: '',
      to: '',
      from: '',
      firstname: '',
      lastname: '',
      externalContactId: '',
      description: '',
      contacts: [] as platformClient.Models.ExternalContact[],
      bodyText: 'aaaaaa<br>aaaaaa'
    }
  },
  methods: {
    async getExternalContacts () {
      this.isLoading = true
      if (this.searchStr.charAt(0) === '0') {
        this.searchStr = `+33${this.searchStr.substring(1)}`
      }
      await genesysCloudService
        .getExternalContacts(this.searchStr)
        .then((contacts) => {
          if (contacts) {
            this.contacts = contacts
          }
        })
        .then(() => {
          this.isLoading = false
          this.contactsResult()
        })
        .catch((err) => console.error(err))
    },
    contactsResult () {
      if (this.contacts.length === 0) {
        this.contactsFound = true
        this.isDataLoaded = false
      } else {
        this.contactsFound = false
        this.isDataLoaded = true
      }
    },
    openProfile (contactId: unknown) {
      const url = `https://apps.mypurecloud.de/directory/#/relate-engine/contact/${contactId}`
      window.open(url)
    },
    SendWebhook (
      to: platformClient.Models.PhoneNumber | undefined,
      from: string,
      firstname: string,
      lastname: string,
      contactId: string | undefined
    ) {
      if (to) {
        if (typeof to.e164 === 'string') {
          this.to = to.e164
          this.from = from
          this.firstname = firstname
          this.lastname = lastname
        }
      }
      if (typeof contactId === 'string') {
        this.externalContactId = contactId
      }
      if (!this.selectedOption) {
        this.showAlert = true
      } else {
        // this.showModal = true
        this.showSMS = true
      }
    },
    verifyMobile (phone: platformClient.Models.PhoneNumber | undefined) {
      if (phone === undefined) {
        this.isMobileValid = false
        return this.isMobileValid
      } else {
        var prefix
        prefix = ['+331', '+332', '+333', '+334', '+335']
        if (typeof phone.e164 === 'string') {
          const val = phone.e164.substring(0, 4)
          this.isMobileValid = prefix.some((item) => item === val)
          return !this.isMobileValid
        }
      }
    },
    getQueueAddress (value: unknown[]) {
      this.queueAddress = typeof value[0] === 'string' ? value[0] : ''
      this.selectedOption = typeof value[1] === 'boolean' ? value[1] : false
    },
    Fermer () {
      this.showAlert = false
      // this.showModal = false
    },
    AnnulerSMS (messageStatus: string) {
      this.showSMS = false
      if (messageStatus === 'true') {
        this.description = 'Message envoyé avec succès'
        this.isSent = true
        this.showConfSMS = true
      } else if (messageStatus === 'error') {
        this.description = 'Une erreur est survenue lors du l\'envoi du SMS'
        this.isSent = false
        this.showConfSMS = true
      } else if (messageStatus === 'false') {
        this.showConfSMS = false
      }
      // this.showConfSMS = true
    },
    ConfirmationSMS () {
      this.showConfSMS = false
    }
  }

})

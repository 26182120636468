
import platformClient from 'purecloud-platform-client-v2'
import genesysCloud from './services/genesyscloud-service'
import { defineComponent } from 'vue'
import HomePage from './components/HomePage.vue'
import config from './config/config'

export default defineComponent({
  name: 'SMS APP',
  data () {
    return {
      agentMember: {} as platformClient.Models.User,
      userId: ''
    }
  },
  components: {
    HomePage
  },
  async created (): Promise<void> {
    await genesysCloud.loginImplicitGrant()
    history.pushState({}, '', config.redirectUri)
  },
  async mounted (): Promise<void> {
    await genesysCloud.getUsersMe()
      .then((data) => {
        this.agentMember = data
      }).then(() => {
        this.userId = this.agentMember.id !== undefined ? this.agentMember.id : ''
      })
  }
})

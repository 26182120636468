
import { defineComponent } from 'vue'

const __default__ = defineComponent({
  name: 'ModalSMS',
  props: {
    show: {
      type: Boolean
    },
    description: {
      type: String
    },
    isSent: {
      type: Boolean
    }
  },
  data () {
    return {
      mainColor: '#4dbaad'
    }
  },
  methods: {
    close () {
      this.$emit('closeConfSMS')
    }
  }
})


import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3b2d6da2": (_ctx.mainColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__
import platformClient from 'purecloud-platform-client-v2'
import config from '@/config/config'

const routingApi = new platformClient.RoutingApi()
const externalcontactApi = new platformClient.ExternalContactsApi()
const userApi = new platformClient.UsersApi()
const integrationApi = new platformClient.IntegrationsApi()
const ResponseManagementApi = new platformClient.ResponseManagementApi()

export default {
  // Login to Genesys Cloud
  async loginImplicitGrant (): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search)
    const environment = urlParams.get('environment') || localStorage.getItem('gc-environment') || 'mypurecloud.de'
    const client = platformClient.ApiClient.instance
    client.setPersistSettings(true, 'SMS-app')
    client.setEnvironment(environment)
    localStorage.setItem('gc-environment', environment)
    await client.loginImplicitGrant(config.clientId, config.redirectUri)
    console.log('Authenticated')
  },

  async getUsersMe (): Promise<platformClient.Models.User> {
    const data = await userApi.getUsersMe()
    return data
  },

  // Get the organization's queues.
  // NOTE: For this sample only get the first 100.
  async getQueues (): Promise<undefined | platformClient.Models.Queue[]> {
    const data = await routingApi.getRoutingQueues({ pageSize: 100 })
    return data.entities
  },

  async getExternalContacts (val: string): Promise<undefined | platformClient.Models.ExternalContact[]> {
    const opts = {
      pageSize: 100,
      pageNumber: 1,
      q: ''
    }
    opts.q = val
    const data = await externalcontactApi.getExternalcontactsContacts(opts)
    return data.entities
  },

  async getResponseLibrary (libraryId: string): Promise<undefined | platformClient.Models.Response[]> {
    const data = await ResponseManagementApi.getResponsemanagementResponses(libraryId)
    return data.entities
  },

  async executeAction (actionId: string, body: Record<string, unknown>): Promise<string | unknown> {
    const data = await integrationApi.postIntegrationsActionExecute(actionId, body)
    return data
  }
}

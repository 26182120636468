
export default {

  methods: {
    createNewContact (): void {
      const url = 'https://apps.mypurecloud.de/directory/#/relate-engine/contact/new'
      window.open(url)
    }
  }
}
